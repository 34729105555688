import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { CpCard, CpButton, CpIcon } from "canopy-styleguide!sofe";

const rootComponent = () => {
  return (
    <CpCard
      style={{
        height: "100vh",
        width: "100%",
        padding: "24px 200px",
      }}
    >
      <div
        className="cps-flexible-focus"
        style={{ maxWidth: "130rem", minWidth: "100rem" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <a href="/">
            <img
              src="https://s3-us-west-2.amazonaws.com/cdn.canopytax.com/images/black-canopy-logo.svg"
              alt="canopy"
            />
          </a>
          <div className="cps-wt-semibold">Phone Support 855-616-7305</div>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: 20,
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: 550, display: "flex", alignItems: "center" }}>
            <div>
              <div className="cps-header cp-mb-36 cps-wt-bold">
                {location.hash.includes("404")
                  ? "Oops! The page you were looking for doesn't exist."
                  : "Oops! Looks Like You Don't Have Access"}
              </div>
              <div className="cp-mb-36">
                {location.hash.includes("404")
                  ? "The page you were looking for doesn't exist or was moved. Verify the URL or go back go login page."
                  : "You don't have access to view this URL. If this is a mistake please contact your administrator."}
              </div>
              <CpButton onClick={() => history.pushState(null, null, "#/")}>
                Back to home
              </CpButton>
            </div>
          </div>
          <img
            src="https://s3-us-west-2.amazonaws.com/cdn.canopytax.com/images/404.svg"
            alt="broken plug"
            style={{ width: 600 }}
          />
        </div>
        <div style={{ display: "flex" }} className="cp-mt-40">
          <CpCard className="cp-p-16 cp-mr-16">
            <div style={{ display: "flex" }} className="cp-mb-16">
              <CpIcon className="cp-mr-16" name="help-circle-open-large" />
              <div className="cps-subheader">Need Help?</div>
            </div>
            <div className="cp-mb-16">
              Are you having trouble finding what you are looking for? Maybe our
              support guides can provide some guidance.
            </div>
            <a href="https://support.getcanopy.com/hc/en-us">Go to support guides</a>
          </CpCard>
          <CpCard className="cp-p-16 cp-mr-16">
            <div style={{ display: "flex" }} className="cp-mb-16">
              <CpIcon className="cp-mr-16" name="person-people" />
              <div className="cps-subheader">Ask the Community</div>
            </div>
            <div className="cp-mb-16">
              Connect with other tax and accounting professionals to compare
              notes, get help or just ask a question.
            </div>
            <a href="https://community.canopytax.com/">Go to the community</a>
          </CpCard>
          <CpCard className="cp-p-16">
            <div style={{ display: "flex" }} className="cp-mb-16">
              <CpIcon className="cp-mr-16" name="misc-pencil-paintbrush" />
              <div className="cps-subheader">Make a Feature Request</div>
            </div>
            <div className="cp-mb-16">
              Most of the best product innovations come from Canopy customers.
              We would love to hear your voice!
            </div>
            <a href="https://community.canopytax.com/categories/feature-requests">
              Request a feature
            </a>
          </CpCard>
        </div>
      </div>
    </CpCard>
  );
};

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent,
  errorBoundary(err, info, props) {
    return <div>An error has occured</div>;
  },
});
export const bootstrap = reactLifecycles.bootstrap;
export const mount = reactLifecycles.mount;
export const unmount = reactLifecycles.unmount;
